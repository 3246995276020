import React, { useState } from "react";

import "./Footer.scss";
import { images } from "../../constants";
import { client } from "../../client";
import { AppWrap, MotionWrap } from "../../wrapper";

const Footer = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { name, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    if (name && email && message) {
      setLoading(true);

      const contact = {
        _type: "contact",
        name: name,
        email: email,
        message: message,
      };

      discordSend();

      client.create(contact).then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
      });
    }
  };

  const discordSend = () => {
    const content = {
      content: "Nouveau message",
      embeds: [
        {
          title: name,
          description: message,
          footer: {
            text: email,
          },
        },
      ],
    };

    fetch(process.env.REACT_APP_DISCORD_WEBHOOK, {
      method: "POST",
      body: JSON.stringify(content),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  return (
    <>
      <h2 className="head-text">Want to chat with me?</h2>

      <div className="app__footer-cards">
        <div className="app__footer-card">
          <img src={images.email} alt="email" />
          <a href="mailto:jonatan.baumgartner@ik.me" className="p-text">
            jonatan.baumgartner@ik.me
          </a>
        </div>
        <div className="app__footer-card">
          <img src={images.mobile} alt="mobile" />
          <a href="tel: +41 32 510 20 33" className="p-text">
            +41 32 510 20 33
          </a>
        </div>
      </div>

      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input
              className="p-text"
              type="text"
              placeholder="Your name"
              value={name}
              onChange={handleChangeInput}
              name="name"
            />
          </div>
          <div className="app__flex">
            <input
              className="p-text"
              type="email"
              placeholder="Your Email"
              value={email}
              onChange={handleChangeInput}
              name="email"
            />
          </div>
          <div>
            <textarea
              className="p-text"
              placeholder="Your message"
              value={message}
              name="message"
              onChange={handleChangeInput}
            />
          </div>
          <button type="button" className="p-text" onClick={handleSubmit}>
            {loading ? "Sending" : "Send message"}
          </button>
        </div>
      ) : (
        <div>
          <h3 className="head-text">Thank you for getting in touch</h3>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, "app__footer"),
  "contact",
  "app__graybg"
);
